import styled from "styled-components";

export const HeroSection = styled.section`
  display:grid;
  grid-template-columns: minmax(2rem,1fr) repeat(12,minmax(auto,9rem)) minmax(2rem,1fr);
  grid-template-rows: auto;
  min-height:60vh;
  gap: 0 1rem;
  position:relative;
  z-index:1;
  
  &.homepage{
    min-height: 100vh;
  }

  @media ${({theme}) => theme.breakpoints.tablet}{
    grid-template-columns: minmax(2rem,1fr) repeat(8,minmax(auto,6rem)) minmax(2rem,1fr);
    min-height: 80vh;
    padding-top: 80px;

    ${props => props.$OurBeliefs && `
      min-height: 0vh;
      padding-top: 10vh;
      padding-bottom: 10vh;
    `}
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    grid-template-columns: minmax(1rem,1fr) repeat(4,minmax(auto,7rem)) minmax(1rem,1fr);
    padding-top: 64px;

    ${props => props.$OurBeliefs && `
      min-height: 0vh;
      padding-top: 10vh;
      padding-bottom: 5vh;
    `}
  }

  ${props => props.$OurBeliefs && `
    min-height: 0vh;
    padding-top: 20vh;
    padding-bottom: 10vh;
  `}
`