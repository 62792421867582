import styled from "styled-components";

export const ListWrapper = styled.ul`
  display:flex;
  flex-flow:column wrap;
  gap:.5rem 0;
  padding-left: 1.5rem;
  
  &.padding{
    padding: 2rem 0rem;
  }
`

export const ListItem = styled.li`
  list-style: disc inside;
  font-size:1rem;
  color: ${({theme}) => theme.colors.darkText};
`
